var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.registrations,"search":_vm.search,"loading":_vm.loading},on:{"click:row":_vm.upsertItem},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Player Registrations")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('registrations-form',{ref:"upsert",on:{"updated":function($event){return _vm.get()}}}),(_vm.canCreate)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.upsertItem}},[_vm._v("Create New Registration")]):_vm._e(),(_vm.downloadUri)?_c('v-btn',{attrs:{"color":"primary","href":_vm.downloadUri}},[_vm._v("Download")]):_vm._e(),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('c-delete-confirm',{ref:"deleteConfirm",attrs:{"title":function (item) { return ("the registration for " + (item.firstName) + " " + (item.lastName)); }},on:{"confirmed":_vm.deleteConfirmed}})],1)]},proxy:true},{key:"item.primary",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.emergencyContact1Name)+" ")]}},{key:"item.primaryPhone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.emergencyContact1Phone)+" ")]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdDate))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.upsertItem(item)}}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDelete(item)}}},[_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v(" mdi-trash-can-outline ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }