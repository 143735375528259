
















































import { Component, Vue, Emit } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { RegistrationsModule, Registration } from "@/store/registrations-module";
import { TeamsApi, Team } from "@/store/teams-api";
import CForm from "@/common/components/CForm.vue";
import Step1 from "./Step1.vue";
import Step2 from "./Step2.vue";
import Step3 from "./Step3.vue";
import Step4 from "./Step4.vue";
import Step5 from "./Step5.vue";

@Component({ components: { CForm, Step1, Step2, Step3, Step4, Step5 } })
export default class RegistrationsForm extends Vue {
    registrationsStore = getModule(RegistrationsModule);
    teamsStore = new TeamsApi();
    dialog = false;
    loading = false;
    readonly = true;
    step = 1;
    step1 = true;
    step2 = true;
    step3 = true;
    step4 = true;
    step5 = true;
    item: Registration = {} as Registration;
    teams: Team[] = [];

    get title() {
        return !this.item.id ? "New Registration" : "Edit Registration";
    }

    open(id: number, readonly = true) {
        this.readonly = readonly;
        this.step = 1;
        this.step1 = this.step2 = this.step3 = this.step4 = this.step5 = true;
        return Promise.all([
            this.teamsStore.getAll({ teamCategory: "" }).then((res) => (this.teams = res.data)),
            this.registrationsStore.get({ id: id }).then((res) => (this.item = res.data)),
        ]).then(() => (this.dialog = true));
    }

    close() {
        this.dialog = false;
    }

    prev() {
        if (this.step == 1) {
            this.close();
            return;
        }
        this.step = this.step - 1;
    }

    next(valid: boolean) {
        if (valid) this.step = this.step + 1;
    }

    @Emit("updated")
    save() {
        this.loading = true;
        return this.registrationsStore
            .save({ registration: this.item })
            .then(() => (this.dialog = false))
            .finally(() => (this.loading = false));
    }
}
