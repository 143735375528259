






















































import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { RegistrationsModule, RegistrationSummary } from "@/store/registrations-module";
import { ProfileModule } from "@/store/profile-module";
import RegistrationsForm from "./RegistrationsForm.vue";
import formatDate from "@/common/filters/format-date";
import CDeleteConfirm from "@/common/components/CDeleteConfirm.vue";

@Component({
    components: { RegistrationsForm, CDeleteConfirm },
    filters: { formatDate },
})
export default class Registrations extends Vue {
    @Prop() type!: "my" | "team" | "all";
    @Ref() readonly upsert!: RegistrationsForm;
    @Ref() readonly deleteConfirm!: CDeleteConfirm;

    registrationStore = getModule(RegistrationsModule);
    profileModule = getModule(ProfileModule);
    registrations: RegistrationSummary[] = [];
    search = "";
    loading = false;

    get headers() {
        let _headers = [
            { text: "First Name", value: "firstName" },
            { text: "Last Name", value: "lastName" },
            { text: "BI PIN", value: "biPin" },
            { text: "Primary Contact", value: "primary" },
            { text: "Primary Phone", value: "primaryPhone" },
            { text: "Created", value: "createdDate" },
            { text: "Actions", value: "actions", sortable: false },
        ];
        if (this.type != "team") {
            _headers.splice(5, 0, { text: "Team", value: "teamName" });
        }
        return _headers;
    }

    get canCreate() {
        return this.type === "my";
    }

    get downloadUri() {
        switch (this.type) {
            case "team":
                return this.profileModule.profile.admin ||
                    this.profileModule.profile.teams?.includes(+this.$route.params.id)
                    ? `/api/registrations/download/team/${this.$route.params.id}`
                    : null;
            case "all":
                return this.profileModule.profile.admin ? "/api/registrations/download" : null;
            default:
                return null;
        }
    }

    mounted() {
        this.get();
    }

    get() {
        this.loading = true;
        switch (this.type) {
            case "team":
                return this.registrationStore
                    .getTeam({ teamId: this.$route.params.id || "" })
                    .then((res) => (this.registrations = res.data))
                    .then(() => (this.loading = false));
            case "all":
                return this.registrationStore
                    .getAll()
                    .then((res) => (this.registrations = res.data))
                    .then(() => (this.loading = false));
            default:
                return this.registrationStore
                    .getMy()
                    .then((res) => (this.registrations = res.data))
                    .then(() => (this.loading = false));
        }
    }

    upsertItem(item: RegistrationSummary) {
        let readonly = this.type !== "my";
        this.upsert.open(item.id, readonly);
    }

    confirmDelete(item: RegistrationSummary) {
        this.deleteConfirm.open(item);
    }

    deleteConfirmed(item: RegistrationSummary) {
        this.loading = true;
        this.registrationStore
            .removeRegistration({ registrationId: item.id })
            .then(() => this.get())
            .finally(() => (this.loading = false));
    }
}
